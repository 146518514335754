<template>
  <div class="component-wrap">
    <div class="banner" id="pageTop"></div>
    <div class="header">
      <div class="crumbs">
        {{ $t("practice.current_location")
        }}<span>{{ practiceEnum[practiceType] }}</span>
      </div>
    </div>
    <a-spin :spinning="spinning">
      <div class="content-wrap">
        <div class="cards">
          <div class="card left">
            <div class="number">
              <div class="top">
                <strong style="color: #2ec45a">{{
                  dataInfo.questionNum - dataInfo.wrongNum
                }}</strong>
                <p>{{ $t("practice.correct_quesition") }}</p>
              </div>
              <div class="bottom">
                <strong style="color: #e82f2e">{{ dataInfo.wrongNum }}</strong>
                <p>{{ $t("practice.wrong_quesition") }}</p>
              </div>
            </div>
            <div class="progress">
              <a-progress
                type="circle"
                strokeColor="#2ec45a"
                :width="150"
                :strokeWidth="12"
                :percent="dataInfo.rightRate.toFixed(0)"
              >
                <template #format="percent">
                  <div class="progress-explain">
                    <span>{{ percent }}%</span>
                    <p>{{ $t("Lab_Lesson_CorrectRate") }}</p>
                  </div>
                </template>
              </a-progress>
            </div>
          </div>
          <div class="card right">
            <div class="number">
              <div class="top">
                <strong>{{ formatSeconds(dataInfo.useTime) }}</strong>
                <p>{{ $t("practice.use_time") }}</p>
              </div>
              <div
                class="bottom"
                v-if="
                  companyInfo.menu &&
                  companyInfo.menu.indexOf('integral') !== -1
                "
              >
                <strong>+{{ dataInfo.integral }}</strong>
                <p>
                  {{ $t("practice.integral_2")
                  }}<span v-if="dataInfo.integralStatus == 2">{{
                    $t("practice.limit_reached")
                  }}</span>
                </p>
              </div>
            </div>
            <div class="diamonds">
              <img src="@/assets/image/practice/diamonds.png" alt="" />
            </div>
          </div>
        </div>
        <div class="btns">
          <a-button style="margin-right: 24px" @click="back">
            {{ $t("go_back") }}
          </a-button>
          <a-button type="primary" @click="again" v-if="!isHistory">
            {{ $t("practice.one_more_group") }}
          </a-button>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { practiceResult } from "@/api/practice";
import ls from "@/utils/local-storage";
import { formatSeconds } from "@/utils/tools";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const companyInfo = computed(() => store.getters.companyInfo);
    const state = reactive({
      spinning: true,
      practiceType: Number(route.query.type || 0),
      submitId: Number(route.query.submitId || 0),
      isHistory: Number(route.query.isHistory || 0) == 1,
      folderIds: route.query.folderIds,
      labelIds: route.query.labelIds,
      practiceEnum: {
        1: proxy.$t("practice.mode.daily"),
        2: proxy.$t("practice.mode.interest"),
        3: proxy.$t("practice.mode.simulation"),
        4: proxy.$t("practice.mode.self"),
      },
      dataInfo: {
        questionNum: 0,
        wrongNum: 0,
        rightRate: 0,
        useTime: 0,
        integral: 0,
      },
    });

    const back = () => {
      router.go(-1);
    };

    const again = () => {
      ls.set("newPractice", true);
      router.replace({
        path: "/mine/practice/detail",
        query: {
          type: state.practiceType,
          folderIds: state.folderIds,
          labelIds: state.labelIds,
        },
      });
    };

    const getDetail = () => {
      state.spinning = true;
      practiceResult(state.submitId).then((res) => {
        state.spinning = false;
        if (res.ret === 0) {
          state.dataInfo = res.data;
        }
      });
    };
    getDetail();

    return {
      formatSeconds,
      companyInfo,
      ...toRefs(state),
      back,
      again,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
}
.banner {
  width: 100%;
  height: 200px;
  background-image: url("~@/assets/image/practice/banner.png");
  background-size: 100% 100%;
  border-radius: 8px 8px 0 0;
}
.header {
  margin: 0 24px;
  padding: 24px 0;
  line-height: 26px;
  .mixinFlex(space-between; center);
  .crumbs {
    font-size: 14px;
    color: #999;
    span {
      color: @color-theme;
    }
  }
}
.content-wrap {
  margin: 0 24px;
  .cards {
    .mixinFlex(space-between);
    .card {
      width: 443px;
      height: 214px;
      border-radius: 4px;
      padding: 32px 24px;
      .mixinFlex(space-between; center);
      &.left {
        background: linear-gradient(
          123.08deg,
          rgba(207, 253, 255, 1) 0%,
          rgba(212, 255, 215, 1) 100%
        );
      }
      &.right {
        background: linear-gradient(
          135deg,
          rgba(237, 220, 242, 1) 0%,
          rgba(245, 243, 223, 1) 100%
        );
      }
      .number {
        color: #333;
        height: 100%;
        .mixinFlex(space-between;);
        flex-direction: column;
        strong {
          font-size: 32px;
          font-weight: 600;
          line-height: 1;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          margin: 6px 0 0;
          span {
            color: #666;
          }
        }
      }
      .progress-explain {
        text-align: center;
        padding-top: 6px;
        span {
          font-size: 38px;
          font-weight: 700;
          line-height: 40px;
          display: inline-block;
          height: 40px;
          color: #2ec45a;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          margin: 4px 0 0;
          color: #666;
        }
      }
      .diamonds {
        .mixinImgWrap(171px; 138px);
      }
    }
  }
  .btns {
    text-align: center;
    margin-top: 120px;
  }
}
</style>
